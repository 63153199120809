import { useTranslation } from "@pancakeswap/localization";
import { PropsWithChildren } from "react";
import { Text, Box, CheckmarkCircleIcon, HiveCheckmarkCircleIcon, AutoColumn } from "@pancakeswap/uikit";

export const SwapTransactionReceiptModalContent: React.FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <Box width="100%">
      <Box margin="auto auto 20px auto" width="fit-content">
        <HiveCheckmarkCircleIcon color="success" width={50} height={50} />
      </Box>
      <AutoColumn gap="12px" justify="center">
        <Text bold textAlign="center" fontSize="20px">
          {t("Transaction receipt")}
        </Text>
        {children}
      </AutoColumn>
    </Box>
  );
};
