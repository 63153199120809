import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="15" height="15" viewBox="0 0 15 15" {...props}>
      <rect width="15" height="15" rx="7.5" fill="#FFB246" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.14053 11.9855L10.9532 10.2344C10.9825 10.2164 11 10.1866 11 10.1548C11 10.123 10.9825 10.0932 10.9532 10.0753L8.14053 8.32312C8.10869 8.30328 8.06667 8.30251 8.03396 8.32119C8.00061 8.34056 7.98214 8.37505 7.98617 8.41048V9.6548H5.60664C5.60664 9.6548 4.32402 9.68419 4 8.80109C4.06869 9.44642 4.43921 10.0355 5.01972 10.4224C5.26379 10.5761 5.55569 10.657 5.85401 10.6538H7.97041V11.8987C7.97202 11.9351 7.9942 11.9684 8.02929 11.987C8.06383 12.0048 8.10654 12.0043 8.14053 11.9856V11.9855ZM6.85901 4.01662L4.04687 5.76721C4.01755 5.78525 4 5.81513 4 5.84701C4 5.87894 4.01755 5.90877 4.04687 5.92687L6.85869 7.67676C6.89054 7.69658 6.93247 7.69753 6.96532 7.67915C6.99895 7.65993 7.0176 7.62538 7.01357 7.58989V6.34605H9.39325C9.39325 6.34605 10.6755 6.31766 11 7.20009C10.9314 6.55497 10.5609 5.96602 9.98031 5.57926C9.73599 5.42545 9.44375 5.34453 9.14514 5.3479H7.01494V4.10307C7.01921 4.06738 7.00046 4.03258 6.96665 4.01333C6.93347 3.99458 6.89086 3.99569 6.85901 4.01617V4.01662Z"
        fill="white"
      />
    </Svg>
  );
};

export default Icon;
