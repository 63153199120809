import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg {...props} width="41" height="36" viewBox="0 0 41 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_5896_6067" maskUnits="userSpaceOnUse" x="0" y="0" width="41" height="36">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H41V36H0V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_5896_6067)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M36.4441 6.19058e-08H4.55606C3.34593 6.19058e-08 2.18784 0.475033 1.3344 1.31799C0.479944 2.16189 -0.000200289 3.30653 6.26763e-08 4.50005V31.5C6.26763e-08 32.6952 0.480945 33.8391 1.3344 34.682C2.18784 35.525 3.34593 36 4.55606 36H13.668V31.5H4.55606V9.0001H36.4441V31.5H27.332V36H36.4441C37.6543 36 38.8122 35.525 39.6658 34.682C40.5193 33.8352 41 32.6913 41 31.5V4.50005C41 3.3048 40.5193 2.16095 39.6658 1.31799C38.8114 0.474044 37.6525 -0.000197827 36.4441 6.19058e-08ZM20.5001 13.5L11.388 22.5H18.2201V36H22.776V22.5H29.6081L20.5001 13.5Z"
          fill="#FFB246"
        />
      </g>
    </Svg>
  );
};

export default Icon;
