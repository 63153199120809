import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | HiveSwap',
  defaultTitle: 'HiveSwap',
  description: 'HiveSwap is a Liquidity Pool-Based DEX for the Bitcoin L2 Ecosystem.',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@HiveSwap',
    site: '@HiveSwap',
  },
  openGraph: {
    title: 'HiveSwap-Liquidity Pool-Based DEX for the Bitcoin L2 Ecosystem',
    description: 'HiveSwap is a Liquidity Pool-Based DEX for the Bitcoin L2 Ecosystem.',
    images: [{ url: 'https://raw.githubusercontent.com/hiveswap/assets/main/hiveswap-banner.png' }],
  },
}
