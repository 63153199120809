import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="14" height="14" viewBox="0 0 14 14" {...props}>
      <mask id="mask0_5896_3100" maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="14">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H14V14H0V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_5896_3100)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.99958 0C3.09927 0 -0.000976562 3.10002 -0.000976562 7.00004C-0.000976562 10.9 3.09927 14 6.99958 14C10.8998 14 14 10.9 14 7.00004C14 3.10002 10.8998 0 6.99958 0ZM6.99958 13C3.69932 13 0.999127 10.3 0.999127 7.00004C0.999127 3.70002 3.69932 1.00003 6.99958 1.00003C10.2998 1.00003 13 3.70002 13 7.00004C13 10.3 10.2998 13 6.99958 13Z"
          fill="#FFB246"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.4286 6.4286H7.5714V3.57145C7.5714 3.22858 7.34282 3 7.00005 3C6.65718 3 6.4286 3.22858 6.4286 3.57145V6.4286H3.57145C3.22858 6.4286 3 6.65718 3 7.00005C3 7.34282 3.22858 7.5714 3.57145 7.5714H6.4286V10.4286C6.4286 10.7714 6.65718 11 7.00005 11C7.34282 11 7.5714 10.7714 7.5714 10.4286V7.5714H10.4286C10.7714 7.5714 11 7.34282 11 7.00005C11 6.65718 10.7714 6.4286 10.4286 6.4286Z"
        fill="#FFB246"
      />
    </Svg>
  );
};

export default Icon;
