import { ChainId } from '@pancakeswap/chains'
import { BIG_ZERO } from '@pancakeswap/utils/bigNumber'
import BigNumber from 'bignumber.js'
import { chainlinkOracleABI } from 'config/abi/chainlinkOracle'
import contracts from 'config/constants/contracts'
import { publicClient } from 'utils/wagmi'
import { formatUnits, parseEther } from 'viem'
import { FAST_INTERVAL } from 'config/constants'
import { useQuery } from '@tanstack/react-query'
import { quoterV2ABI } from '@pancakeswap/v3-sdk'
import { V3_QUOTER_ADDRESSES } from '@pancakeswap/smart-router'
import { mapTokens } from '@pancakeswap/tokens'

// for migration to bignumber.js to avoid breaking changes
export const useCakePrice = ({ enabled = true } = {}) => {
  const { data } = useQuery<BigNumber, Error>({
    queryKey: ['cakePrice'],
    queryFn: async () => new BigNumber(await getHivePriceFromQuoter()),
    staleTime: FAST_INTERVAL,
    refetchInterval: FAST_INTERVAL,
    enabled,
  })
  return data ?? BIG_ZERO
}

export const getCakePriceFromOracle = async () => {
  const data = await publicClient({ chainId: ChainId.BSC }).readContract({
    abi: chainlinkOracleABI,
    address: contracts.chainlinkOracleCAKE[ChainId.BSC],
    functionName: 'latestAnswer',
  })

  return formatUnits(data, 8)
}

export const getHivePriceFromQuoter = async () => {
  return '0.01'
  // @ts-ignore
  const data = await publicClient({ chainId: ChainId.MAP }).readContract({
    abi: quoterV2ABI,
    address: V3_QUOTER_ADDRESSES[ChainId.MAP],
    functionName: 'quoteExactInputSingle',
    args: [
      {
        tokenIn: mapTokens.cake.address,
        tokenOut: mapTokens.usdt.address,
        fee: 2500,
        amountIn: parseEther('1'),
        sqrtPriceLimitX96: 0n,
      },
    ],
  })

  return new BigNumber(data?.[0]?.toString() ?? 0).div(parseEther('1').toString()).toFixed()
}
