import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="23" height="18" viewBox="0 0 23 18" {...props}>
      <mask id="mask0_5896_3621" maskUnits="userSpaceOnUse" x="0" y="0" width="23" height="18">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H23V18H0V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_5896_3621)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.425 15.8824C22.7426 15.8824 23 16.1194 23 16.4118V17.4706C23 17.763 22.7426 18 22.425 18H0.575C0.257562 18 0 17.763 0 17.4706V16.4118C0 16.1194 0.257562 15.8824 0.575 15.8824H22.425ZM4.59206 5.73349L4.6 5.82353V12.1765C4.60061 12.3744 4.48109 12.556 4.2905 12.6474C4.09971 12.7388 3.86956 12.7246 3.69392 12.6106L3.61802 12.5513L0.168015 9.37486C-0.0281367 9.19369 -0.0558517 8.90944 0.102427 8.69929L0.168015 8.62514L3.61802 5.44867C3.7697 5.30845 3.99387 5.25787 4.19884 5.31755C4.40385 5.37724 4.55553 5.53733 4.59206 5.73254V5.73349ZM22.425 10.5882C22.7426 10.5882 23 10.8252 23 11.1176V12.1765C23 12.4689 22.7426 12.7059 22.425 12.7059H8.625C8.30756 12.7059 8.05 12.4689 8.05 12.1765V11.1176C8.05 10.8252 8.30756 10.5882 8.625 10.5882H22.425ZM22.425 5.29412C22.7426 5.29412 23 5.53112 23 5.82353V6.88235C23 7.17476 22.7426 7.41176 22.425 7.41176H8.625C8.30756 7.41176 8.05 7.17476 8.05 6.88235V5.82353C8.05 5.53112 8.30756 5.29412 8.625 5.29412H22.425ZM22.425 0C22.7426 0 23 0.237 23 0.529412V1.58824C23 1.88065 22.7426 2.11765 22.425 2.11765H0.575C0.257562 2.11765 0 1.88065 0 1.58824V0.529412C0 0.237 0.257562 0 0.575 0H22.425Z"
          fill="#FFB246"
        />
      </g>
    </Svg>
  );
};

export default Icon;
