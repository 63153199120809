import { AtomBox, Box, CurrencyIcon, Flex, FlexGap, NotionIcon, TwitterIcon } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import Link from 'next/link'
import CakePrice from '@pancakeswap/uikit/components/CakePrice/CakePrice'
import { ChainId } from '@pancakeswap/chains'
import React from 'react'
import { styled } from 'styled-components'
import { usePathname } from 'next/navigation'
import { useCakePrice } from 'hooks/useCakePrice'
import { BIG_ZERO } from '@pancakeswap/utils/bigNumber'
import { BooleanParam, useQueryParam, withDefault } from 'use-query-params'
import Timer from '@pancakeswap/uikit/components/Svg/Icons/Timer'

const SidebarWrapper = styled(Box)<{ openMenu?: boolean }>`
  width: 240px;
  background: ${({ theme }) => theme.colors.backgroundAlt};
  height: calc(100vh - 58px);
  position: fixed;
  top: 56px;
  border-right: 1px solid ${({ theme }) => theme.colors.cardBorder};
  padding: 40px 0 40px;
  z-index: 1000000000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: translateX(0);
  transition: transform 0.2s;

  @media screen and (max-width: 800px) {
    transform: ${(props) => (props.openMenu ? 'translateX(0)' : 'translateX(-240px)')};
  }
`

const SidebarSubItem = styled(Link)`
  display: block;
  padding: 10px 0 10px 44px;
  background: ${({ theme }) => theme.colors.background};
  border-radius: 8px;
`

export const Sidebar = () => {
  const { t } = useTranslation()
  const pathname = usePathname()
  const cakePrice = useCakePrice()
  const [openMenu, setOpenMenu] = useQueryParam('open-menu', withDefault(BooleanParam, false))

  return (
    <SidebarWrapper openMenu={openMenu}>
      <div>
        <div style={{ padding: '10px 20px' }}>
          <FlexGap alignItems="center" gap="10px">
            <img
              src="https://raw.githubusercontent.com/hiveswap/assets/main/sidebar-trade-icon.svg"
              alt="svg"
              width="32px"
              height="32px"
            />
            {t('Trade')}
          </FlexGap>
        </div>
        <div>
          <div style={{ padding: '5px 20px' }}>
            <SidebarSubItem
              href="/swap"
              onClick={() => setOpenMenu(false)}
              style={{
                color: pathname?.includes('swap') ? '#FFB246' : 'inherit',
              }}
            >
              {t('Swap')}
            </SidebarSubItem>
          </div>
          <div style={{ padding: '5px 20px' }}>
            <SidebarSubItem
              href="/liquidity"
              onClick={() => setOpenMenu(false)}
              style={{
                color: pathname?.includes('liquidity') ? '#FFB246' : 'inherit',
              }}
            >
              {t('Liquidity')}
            </SidebarSubItem>
          </div>
        </div>
        <Link
          href="/info/v3/map"
          onClick={() => setOpenMenu(false)}
          style={{ display: 'block', padding: '10px 20px', cursor: 'pointer' }}
        >
          <FlexGap
            alignItems="center"
            gap="10px"
            style={{
              color: pathname?.includes('info') ? '#FFB246' : 'inherit',
            }}
          >
            <img
              src="https://raw.githubusercontent.com/hiveswap/assets/main/sidebar-info-icon.svg"
              alt="svg"
              width="32px"
              height="32px"
            />
            {t('Info')}
          </FlexGap>
        </Link>
        <Link
          href="/migration"
          onClick={() => setOpenMenu(false)}
          style={{ display: 'block', padding: '10px 20px', cursor: 'pointer' }}
        >
          <FlexGap
            alignItems="center"
            gap="10px"
            style={{
              color: pathname?.includes('migrate') ? '#FFB246' : 'inherit',
            }}
          >
            <img
              src="https://raw.githubusercontent.com/hiveswap/assets/main/sidebar-migrate-icon.svg"
              alt="svg"
              width="32px"
              height="32px"
            />
            {t('Migrate')}
          </FlexGap>
        </Link>
      </div>
      <FlexGap gap="12px" justifyContent="center" alignItems="center">
        <a href="https://twitter.com/hiveswap_io" target="_blank" rel="noreferrer">
          <TwitterIcon color="primary" />
        </a>
        <a href="https://docs.hiveswap.io" target="_blank" rel="noreferrer">
          <NotionIcon color="primary" />
        </a>
        {/* <CakePrice */}
        {/*  chainId={ChainId.MAP} */}
        {/*  showSkeleton={false} */}
        {/*  cakePriceUsd={cakePrice.eq(BIG_ZERO) ? undefined : cakePrice?.toNumber()} */}
        {/* /> */}
      </FlexGap>
    </SidebarWrapper>
  )
}
