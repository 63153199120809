import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" {...props}>
      <mask id="mask0_5896_3187" maskUnits="userSpaceOnUse" x="0" y="0" width="23" height="23">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H23V23H0V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_5896_3187)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.4992 23C5.15571 23 -0.00170898 17.843 -0.00170898 11.5001C-0.00170898 5.15704 5.15571 0 11.4992 0C17.8426 0 23 5.15704 23 11.5001C23 17.843 17.8426 23 11.4992 23ZM11.4992 1.72503C6.10818 1.72503 1.72345 6.10944 1.72345 11.5001C1.72345 16.8907 6.10818 21.2751 11.4992 21.2751C16.8902 21.2751 21.2749 16.8907 21.2749 11.5001C21.2749 6.10944 16.8902 1.72503 11.4992 1.72503Z"
          fill="black"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.72679 17C9.5103 17 9.29383 16.9042 9.11334 16.7319L5.23456 12.613C5.19843 12.5747 5.18038 12.5364 5.14425 12.4981C5.12632 12.4789 5.10825 12.4598 5.10825 12.4406L5.05407 12.4023L5.03603 12.3256C5 12.1533 5 12.0384 5 11.9426C5 11.8277 5 11.6935 5.09018 11.5786C5.14425 11.4827 5.18038 11.4061 5.25249 11.3678L9.0953 7.26823C9.2577 7.09579 9.47419 7 9.69068 7C9.90715 7 10.1236 7.09579 10.3041 7.26823C10.4845 7.44058 10.5747 7.67047 10.5747 7.91951C10.5747 8.1494 10.4845 8.37928 10.3221 8.57092L7.94074 11.0805H18.152C18.603 11.0805 19 11.5019 19 11.9808C19 12.4598 18.603 12.8813 18.152 12.8813H7.94074L10.3221 15.4291C10.6469 15.774 10.6649 16.3295 10.3221 16.7127C10.1598 16.8851 9.94329 17 9.72679 17Z"
        fill="black"
      />
    </svg>
  );
};

export default Icon;
