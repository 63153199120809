import { ChainId } from '@pancakeswap/chains'

// export const PANCAKE_EXTENDED = 'https://raw.githubusercontent.com/hiveswap/assets/main/hiveswap-tokens.json'
export const PANCAKE_EXTENDED = 'https://cdn.jsdelivr.net/gh/hiveswap/assets@master/hiveswap-tokens.json'

export const PANCAKE_ETH_MM = 'https://tokens.pancakeswap.finance/pancakeswap-eth-mm.json'
export const PANCAKE_BSC_MM = 'https://tokens.pancakeswap.finance/pancakeswap-bnb-mm.json'

const MAP_URLS = [PANCAKE_EXTENDED]

// List of official tokens list
export const OFFICIAL_LISTS = [PANCAKE_EXTENDED]

export const UNSUPPORTED_LIST_URLS: string[] = []
export const WARNING_LIST_URLS: string[] = []

// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [...MAP_URLS]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [PANCAKE_EXTENDED]

export const MULTI_CHAIN_LIST_URLS: { [chainId: number]: string[] } = {
  [ChainId.MAP]: MAP_URLS,
}
