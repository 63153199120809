import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg {...props} viewBox="0 0 41 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_5896_4297" maskUnits="userSpaceOnUse" x="0" y="0" width="41" height="39">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H41V39H0V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_5896_4297)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.5746 9C8.70477 9 8 8.32415 8 7.49048V1.50957C8 0.672597 8.70881 0 9.5746 0C10.4402 0 11.1493 0.679567 11.1493 1.50957V7.49048C11.1567 8.32761 10.4477 9 9.5746 9ZM31.4254 9C30.5558 9 29.8507 8.32415 29.8507 7.49048V1.50957C29.8507 0.672597 30.5596 0 31.4254 0C32.291 0 33 0.679567 33 1.50957V7.49048C33 8.32761 32.291 9 31.4254 9Z"
          fill="#FFB246"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 13.6549H41V8.02332C41 6.35503 39.6382 5 37.9617 5H33.9202V8.4241C33.9202 9.91023 32.7049 11.1195 31.2114 11.1195C29.7177 11.1195 28.5023 9.91023 28.5023 8.4241V5H12.4904V8.4241C12.4904 9.91023 11.275 11.1195 9.78152 11.1195C8.28783 11.1195 7.0725 9.91023 7.0725 8.4241V5H3.03832C1.36178 5 0 6.35503 0 8.02332V13.6549ZM0 16.7365V36.0859C0 37.6958 1.31062 39 2.92856 39H38.0714C39.6895 39 41 37.6958 41 36.0859V16.7365H0ZM29.1026 22.3606L19.248 31.5253C18.8088 31.9334 18.2377 32.1372 17.6739 32.1372C17.0662 32.1372 16.4585 31.8969 16.0046 31.4306L11.8314 27.0813C11.2384 26.4694 11.2604 25.4933 11.8827 24.903C12.4977 24.313 13.4788 24.3421 14.0718 24.9541L17.7105 28.7423L26.9941 20.1095C27.6165 19.5265 28.5976 19.563 29.1833 20.1823C29.7616 20.8015 29.7323 21.7778 29.1026 22.3606Z"
          fill="#FFB246"
        />
      </g>
    </Svg>
  );
};

export default Icon;
