import { mapTokens, opBnbTokens } from '@pancakeswap/tokens'
import { FeeAmount } from '@pancakeswap/v3-sdk'

import { defineFarmV3Configs } from '../src/defineFarmV3Configs'

// export const farmsV3 = defineFarmV3Configs([
//   {
//     pid: 1,
//     lpAddress: '0x10b8C60B45191C2E32a5AC7AC604Ac3807dEa48A',
//     token0: mapTokens.map,
//     token1: mapTokens.usdt,
//     feeAmount: FeeAmount.MEDIUM,
//   },
// ])

export const farmsV3 = defineFarmV3Configs([])
