import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 104 101" version="1.1" {...props}>
      <g id="g-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="gg">
          <polygon
            id="Fill-1"
            fill="#E48023"
            points="19.0506 34.3499 19.0506 14.1399 36.5506 4.0399 54.0506 14.1399 54.0506 34.3499 36.5506 44.4599"
          />
          <path
            d="M36.5503,0 L33.0503,2.02 L19.0503,10.1 L15.5503,12.12 L15.5503,16.16 L15.5503,32.33 L15.5503,36.37 L19.0503,38.39 L33.0503,46.47 L36.5503,48.49 L40.0503,46.47 L54.0513,38.39 L57.5513,36.37 L57.5513,32.33 L57.5513,16.16 L57.5513,12.12 L54.0513,10.1 L40.0503,2.02 L36.5503,0 Z M22.5513,32.33 L22.5513,16.16 L36.5503,8.08 L50.5503,16.16 L50.5503,32.33 L36.5503,40.41 L22.5513,32.33 Z"
            id="Fill-2"
            fill="#FFC55F"
          />
          <polygon
            id="Fill-3"
            fill="#E48023"
            points="49.9505 34.3499 49.9505 14.1399 67.4505 4.0399 84.9505 14.1399 84.9505 34.3499 67.4505 44.4599"
          />
          <path
            d="M67.45,0 L63.95,2.02 L49.95,10.1 L46.45,12.12 L46.45,16.16 L46.45,32.33 L46.45,36.37 L49.95,38.39 L63.95,46.47 L67.45,48.49 L70.95,46.47 L84.95,38.39 L88.45,36.37 L88.45,32.33 L88.45,16.16 L88.45,12.12 L84.95,10.1 L70.95,2.02 L67.45,0 Z M53.45,32.33 L53.45,16.16 L67.45,8.08 L81.45,16.16 L81.45,32.33 L67.45,40.41 L53.45,32.33 Z"
            id="Fill-4"
            fill="#FFC55F"
          />
          <polygon
            id="Fill-5"
            fill="#E48023"
            points="3.5003 60.5003 3.5003 40.3003 21.0003 30.1903 38.5003 40.3003 38.5003 60.5003 21.0003 70.6093"
          />
          <path
            d="M17.5,28.17 L3.501,36.25 L0,38.27 L0,42.31 L0,58.48 L0,62.52 L3.501,64.541 L17.5,72.62 L21,74.64 L24.5,72.62 L38.501,64.541 L42.001,62.52 L42.001,58.48 L42.001,42.31 L42.001,38.27 L38.501,36.25 L24.5,28.17 L21,26.15 L17.5,28.17 Z M7.001,58.48 L7.001,42.31 L21,34.231 L35.001,42.31 L35.001,58.48 L21,66.559 L7.001,58.48 Z"
            id="Fill-6"
            fill="#FFC55F"
          />
          <polygon
            id="Fill-7"
            fill="#E48023"
            points="34.5003 60.5003 34.5003 40.3003 52.0003 30.1903 69.5003 40.3003 69.5003 60.5003 52.0003 70.6093"
          />
          <path
            d="M52.0008,26.1502 L48.5008,28.1702 L34.5008,36.2502 L31.0008,38.2702 L31.0008,42.3102 L31.0008,58.4802 L31.0008,62.5202 L34.5008,64.5402 L48.5008,72.6202 L52.0008,74.6402 L55.5008,72.6202 L69.5008,64.5402 L73.0008,62.5202 L73.0008,58.4802 L73.0008,42.3102 L73.0008,38.2702 L69.5008,36.2502 L55.5008,28.1702 L52.0008,26.1502 Z M52.0008,34.2302 L66.0008,42.3102 L66.0008,58.4802 L52.0008,66.5602 L38.0008,58.4802 L38.0008,42.3102 L52.0008,34.2302 Z"
            id="Fill-8"
            fill="#FFC55F"
          />
          <polygon
            id="Fill-9"
            fill="#E48023"
            points="65.4002 60.5003 65.4002 40.3003 82.9002 30.1903 100.4002 40.3003 100.4002 60.5003 82.9002 70.6093"
          />
          <path
            d="M79.4002,28.17 L65.4002,36.25 L61.9002,38.27 L61.9002,42.31 L61.9002,58.48 L61.9002,62.52 L65.4002,64.541 L79.4002,72.62 L82.9002,74.64 L86.4002,72.62 L100.4002,64.541 L103.9002,62.52 L103.9002,38.27 L100.4002,36.25 L86.4002,28.17 L82.9002,26.15 L79.4002,28.17 Z M68.9002,58.48 L68.9002,42.31 L82.9002,34.231 L96.9002,42.31 L96.9002,58.48 L82.9002,66.559 L68.9002,58.48 Z"
            id="Fill-10"
            fill="#FFC55F"
          />
          <polygon
            id="Fill-11"
            fill="#E48023"
            points="19.0506 86.6497 19.0506 66.4507 36.5506 56.3397 54.0506 66.4507 54.0506 86.6497 36.5506 96.7597"
          />
          <path
            d="M33.0502,54.3195 L19.0502,62.3995 L15.5502,64.4205 L15.5502,68.4605 L15.5502,84.6305 L15.5502,88.6705 L19.0502,90.6895 L33.0502,98.7695 L36.5502,100.7905 L40.0502,98.7695 L54.0512,90.6895 L57.5512,88.6705 L57.5512,84.6305 L57.5512,68.4605 L57.5512,64.4205 L54.0512,62.3995 L40.0502,54.3195 L36.5502,52.2995 L33.0502,54.3195 Z M22.5512,84.6395 L22.5512,68.4705 L36.5502,60.3895 L50.5512,68.4705 L50.5512,84.6395 L36.5502,92.7195 L22.5512,84.6395 Z"
            id="Fill-12"
            fill="#FFC55F"
          />
          <polygon
            id="Fill-13"
            fill="#E48023"
            points="49.9505 86.6497 49.9505 66.4507 67.4505 56.3397 84.9505 66.4507 84.9505 86.6497 67.4505 96.7597"
          />
          <path
            d="M63.9499,54.3195 L49.9499,62.3995 L46.4499,64.4205 L46.4499,68.4605 L46.4499,84.6305 L46.4499,88.6705 L49.9499,90.6895 L63.9499,98.7695 L67.4499,100.7905 L70.9499,98.7695 L84.9499,90.6895 L88.4499,88.6705 L88.4499,84.6305 L88.4499,68.4605 L88.4499,64.4205 L84.9499,62.3995 L70.9499,54.3195 L67.4499,52.2995 L63.9499,54.3195 Z M53.4499,84.6395 L53.4499,68.4705 L67.4499,60.3895 L81.4499,68.4705 L81.4499,84.6395 L67.4499,92.7195 L53.4499,84.6395 Z"
            id="Fill-14"
            fill="#FFC55F"
          />
          <polygon
            id="Fill-15"
            fill="#E48023"
            points="20.0506 33.7699 20.0506 14.7199 36.5506 5.1999 53.0506 14.7199 53.0506 33.7699 36.5506 43.2999"
          />
          <path
            d="M36.5506,2.3099 L34.0506,3.7499 L20.0506,11.8299 L17.5506,13.2699 L17.5506,16.1599 L17.5506,32.3299 L17.5506,35.2199 L20.0506,36.6599 L34.0506,44.7399 L36.5506,46.1799 L39.0506,44.7399 L53.0506,36.6599 L55.5506,35.2199 L55.5506,32.3299 L55.5506,16.1599 L55.5506,13.2699 L53.0506,11.8299 L39.0506,3.7499 L36.5506,2.3099 Z M36.5506,8.0799 L50.5506,16.1599 L50.5506,32.3299 L36.5506,40.4099 L22.5506,32.3299 L22.5506,16.1599 L36.5506,8.0799 Z"
            id="Fill-16"
            fill="#FFF3D4"
          />
          <polygon
            id="Fill-17"
            fill="#E48023"
            points="50.9505 33.7699 50.9505 14.7199 67.4505 5.1999 83.9505 14.7199 83.9505 33.7699 67.4505 43.2999"
          />
          <path
            d="M67.45,2.3099 L64.95,3.7499 L50.95,11.8299 L48.45,13.2699 L48.45,16.1599 L48.45,32.3299 L48.45,35.2199 L50.95,36.6599 L64.95,44.7399 L67.45,46.1799 L69.95,44.7399 L83.95,36.6599 L86.45,35.2199 L86.45,32.3299 L86.45,16.1599 L86.45,13.2699 L83.95,11.8299 L69.95,3.7499 L67.45,2.3099 Z M67.45,8.0799 L81.45,16.1599 L81.45,32.3299 L67.45,40.4099 L53.45,32.3299 L53.45,16.1599 L67.45,8.0799 Z"
            id="Fill-18"
            fill="#FFF3D4"
          />
          <polygon
            id="Fill-19"
            fill="#E48023"
            points="4.5003 59.93 4.5003 40.87 21.0003 31.35 37.5003 40.87 37.5003 59.93 21.0003 69.451"
          />
          <path
            d="M21.0003,28.4603 L18.5003,29.9003 L4.5003,37.9803 L2.0003,39.4203 L2.0003,42.3103 L2.0003,58.4803 L2.0003,61.3703 L4.5003,62.8103 L18.5003,70.8903 L21.0003,72.3303 L23.5003,70.8903 L37.5003,62.8103 L40.0003,61.3703 L40.0003,58.4803 L40.0003,42.3103 L40.0003,39.4203 L37.5003,37.9803 L23.5003,29.9003 L21.0003,28.4603 Z M21.0003,34.2303 L35.0003,42.3103 L35.0003,58.4803 L21.0003,66.5603 L7.0003,58.4803 L7.0003,42.3103 L21.0003,34.2303 Z"
            id="Fill-20"
            fill="#FFF3D4"
          />
          <polygon
            id="Fill-21"
            fill="#E48023"
            points="35.5003 59.93 35.5003 40.87 52.0003 31.35 68.5003 40.87 68.5003 59.93 52.0003 69.451"
          />
          <path
            d="M52.0008,28.4603 L49.5008,29.9003 L35.5008,37.9803 L33.0008,39.4203 L33.0008,42.3103 L33.0008,58.4803 L33.0008,61.3703 L35.5008,62.8103 L49.5008,70.8903 L52.0008,72.3303 L54.5008,70.8903 L68.5008,62.8103 L71.0008,61.3703 L71.0008,58.4803 L71.0008,42.3103 L71.0008,39.4203 L68.5008,37.9803 L54.5008,29.9003 L52.0008,28.4603 Z M52.0008,34.2303 L66.0008,42.3103 L66.0008,58.4803 L52.0008,66.5603 L38.0008,58.4803 L38.0008,42.3103 L52.0008,34.2303 Z"
            id="Fill-22"
            fill="#FFF3D4"
          />
          <polygon
            id="Fill-23"
            fill="#E48023"
            points="66.4002 59.93 66.4002 40.87 82.9002 31.35 99.4002 40.87 99.4002 59.93 82.9002 69.451"
          />
          <path
            d="M82.9002,28.4603 L80.4002,29.9003 L66.4002,37.9803 L63.9002,39.4203 L63.9002,42.3103 L63.9002,58.4803 L63.9002,61.3703 L66.4002,62.8103 L80.4002,70.8903 L82.9002,72.3303 L85.4002,70.8903 L99.4002,62.8103 L101.9002,61.3703 L101.9002,58.4803 L101.9002,42.3103 L101.9002,39.4203 L99.4002,37.9803 L85.4002,29.9003 L82.9002,28.4603 Z M82.9002,34.2303 L96.9002,42.3103 L96.9002,58.4803 L82.9002,66.5603 L68.9002,58.4803 L68.9002,42.3103 L82.9002,34.2303 Z"
            id="Fill-24"
            fill="#FFF3D4"
          />
          <polygon
            id="Fill-25"
            fill="#E48023"
            points="20.0506 86.0804 20.0506 67.0194 36.5506 57.5004 53.0506 67.0194 53.0506 86.0804 36.5506 95.6004"
          />
          <path
            d="M36.5506,54.6097 L34.0506,56.0497 L20.0506,64.1307 L17.5506,65.5697 L17.5506,68.4607 L17.5506,84.6307 L17.5506,87.5197 L20.0506,88.9607 L34.0506,97.0407 L36.5506,98.4797 L39.0506,97.0407 L53.0506,88.9607 L55.5506,87.5197 L55.5506,84.6307 L55.5506,68.4607 L55.5506,65.5697 L53.0506,64.1307 L39.0506,56.0497 L36.5506,54.6097 Z M36.5506,60.3897 L50.5506,68.4697 L50.5506,84.6397 L36.5506,92.7197 L22.5506,84.6397 L22.5506,68.4697 L36.5506,60.3897 Z"
            id="Fill-26"
            fill="#FFF3D4"
          />
          <polygon
            id="Fill-27"
            fill="#E48023"
            points="50.9505 86.0804 50.9505 67.0194 67.4505 57.5004 83.9505 67.0194 83.9505 86.0804 67.4505 95.6004"
          />
          <path
            d="M67.45,54.6097 L64.95,56.0497 L50.95,64.1307 L48.45,65.5697 L48.45,68.4607 L48.45,84.6307 L48.45,87.5197 L50.95,88.9607 L64.95,97.0407 L67.45,98.4797 L69.95,97.0407 L83.95,88.9607 L86.45,87.5197 L86.45,84.6307 L86.45,68.4607 L86.45,65.5697 L83.95,64.1307 L69.95,56.0497 L67.45,54.6097 Z M67.45,60.3897 L81.45,68.4697 L81.45,84.6397 L67.45,92.7197 L53.45,84.6397 L53.45,68.4697 L67.45,60.3897 Z"
            id="Fill-28"
            fill="#FFF3D4"
          />
          <polygon
            id="Fill-29"
            fill="#E48023"
            points="21.0506 33.2 21.0506 15.3 36.5506 6.35 52.0506 15.3 52.0506 33.2 36.5506 42.15"
          />
          <path
            d="M36.5506,4.62 L35.0506,5.49 L21.0506,13.57 L19.5506,14.44 L19.5506,16.17 L19.5506,32.34 L19.5506,34.07 L21.0506,34.94 L35.0506,43.02 L36.5506,43.89 L38.0506,43.02 L52.0506,34.94 L53.5506,34.07 L53.5506,32.34 L53.5506,16.17 L53.5506,14.44 L52.0506,13.57 L38.0506,5.49 L36.5506,4.62 Z M36.5506,8.08 L50.5506,16.16 L50.5506,32.33 L36.5506,40.41 L22.5506,32.33 L22.5506,16.16 L36.5506,8.08 Z"
            id="Fill-30"
            fill="#6F3319"
          />
          <polygon
            id="Fill-31"
            fill="#E48023"
            points="51.95 33.2 51.95 15.3 67.45 6.35 82.95 15.3 82.95 33.2 67.45 42.15"
          />
          <path
            d="M67.45,4.62 L65.95,5.49 L51.95,13.57 L50.45,14.44 L50.45,16.17 L50.45,32.34 L50.45,34.07 L51.95,34.94 L65.95,43.02 L67.45,43.89 L68.95,43.02 L82.95,34.94 L84.45,34.07 L84.45,32.34 L84.45,16.17 L84.45,14.44 L82.95,13.57 L68.95,5.49 L67.45,4.62 Z M67.45,8.08 L81.45,16.16 L81.45,32.33 L67.45,40.41 L53.45,32.33 L53.45,16.16 L67.45,8.08 Z"
            id="Fill-32"
            fill="#6F3319"
          />
          <polygon
            id="Fill-33"
            fill="#E48023"
            points="5.5003 59.3499 5.5003 41.4499 21.0003 32.4999 36.5003 41.4499 36.5003 59.3499 21.0003 68.2999"
          />
          <path
            d="M21.0003,30.7699 L19.5003,31.6399 L5.5003,39.7199 L4.0003,40.5899 L4.0003,42.3199 L4.0003,58.4909 L4.0003,60.2199 L5.5003,61.0899 L19.5003,69.1699 L21.0003,70.0409 L22.5003,69.1699 L36.5003,61.0899 L38.0003,60.2199 L38.0003,58.4909 L38.0003,42.3199 L38.0003,40.5899 L36.5003,39.7199 L22.5003,31.6399 L21.0003,30.7699 Z M21.0003,34.2299 L35.0003,42.3099 L35.0003,58.4799 L21.0003,66.5599 L7.0003,58.4799 L7.0003,42.3099 L21.0003,34.2299 Z"
            id="Fill-34"
            fill="#6F3319"
          />
          <polygon
            id="Fill-35"
            fill="#E48023"
            points="36.5003 59.3499 36.5003 41.4499 52.0003 32.4999 67.5003 41.4499 67.5003 59.3499 52.0003 68.2999"
          />
          <path
            d="M52.0008,30.7699 L50.5008,31.6399 L36.5008,39.7199 L35.0008,40.5899 L35.0008,42.3199 L35.0008,58.4909 L35.0008,60.2199 L36.5008,61.0899 L50.5008,69.1699 L52.0008,70.0409 L53.5008,69.1699 L67.5008,61.0899 L69.0008,60.2199 L69.0008,58.4909 L69.0008,42.3199 L69.0008,40.5899 L67.5008,39.7199 L53.5008,31.6399 L52.0008,30.7699 Z M52.0008,34.2299 L66.0008,42.3099 L66.0008,58.4799 L52.0008,66.5599 L38.0008,58.4799 L38.0008,42.3099 L52.0008,34.2299 Z"
            id="Fill-36"
            fill="#6F3319"
          />
          <polygon
            id="Fill-37"
            fill="#E48023"
            points="67.4002 59.3499 67.4002 41.4499 82.9002 32.4999 98.4002 41.4499 98.4002 59.3499 82.9002 68.2999"
          />
          <path
            d="M82.9002,30.7699 L81.4002,31.6399 L67.4002,39.7199 L65.9002,40.5899 L65.9002,42.3199 L65.9002,58.4909 L65.9002,60.2199 L67.4002,61.0899 L81.4002,69.1699 L82.9002,70.0409 L84.4002,69.1699 L98.4002,61.0899 L99.9002,60.2199 L99.9002,58.4909 L99.9002,42.3199 L99.9002,40.5899 L98.4002,39.7199 L84.4002,31.6399 L82.9002,30.7699 Z M82.9002,34.2299 L96.9002,42.3099 L96.9002,58.4799 L82.9002,66.5599 L68.9002,58.4799 L68.9002,42.3099 L82.9002,34.2299 Z"
            id="Fill-38"
            fill="#6F3319"
          />
          <polygon
            id="Fill-39"
            fill="#E48023"
            points="21.0506 85.5003 21.0506 67.6003 36.5506 58.6493 52.0506 67.6003 52.0506 85.5003 36.5506 94.4503"
          />
          <path
            d="M36.5506,56.9203 L35.0506,57.7903 L21.0506,65.8703 L19.5506,66.7403 L19.5506,68.4703 L19.5506,84.6403 L19.5506,86.3703 L21.0506,87.2403 L35.0506,95.3193 L36.5506,96.1903 L38.0506,95.3193 L52.0506,87.2403 L53.5506,86.3703 L53.5506,84.6403 L53.5506,68.4703 L53.5506,66.7403 L52.0506,65.8703 L38.0506,57.7903 L36.5506,56.9203 Z M36.5506,60.3903 L50.5506,68.4703 L50.5506,84.6403 L36.5506,92.7203 L22.5506,84.6403 L22.5506,68.4703 L36.5506,60.3903 Z"
            id="Fill-40"
            fill="#6F3319"
          />
          <polygon
            id="Fill-41"
            fill="#E48023"
            points="51.95 85.5003 51.95 67.6003 67.45 58.6493 82.95 67.6003 82.95 85.5003 67.45 94.4503"
          />
          <path
            d="M67.45,56.9203 L65.95,57.7903 L51.95,65.8703 L50.45,66.7403 L50.45,68.4703 L50.45,84.6403 L50.45,86.3703 L51.95,87.2403 L65.95,95.3193 L67.45,96.1903 L68.95,95.3193 L82.95,87.2403 L84.45,86.3703 L84.45,84.6403 L84.45,68.4703 L84.45,66.7403 L82.95,65.8703 L68.95,57.7903 L67.45,56.9203 Z M67.45,60.3903 L81.45,68.4703 L81.45,84.6403 L67.45,92.7203 L53.45,84.6403 L53.45,68.4703 L67.45,60.3903 Z"
            id="Fill-42"
            fill="#6F3319"
          />
          <polygon
            id="Fill-43"
            fill="#E48023"
            points="22.5506 16.1703 22.5506 32.3303 36.5506 40.4103 50.5506 32.3303 50.5506 16.1703 36.5506 8.0803"
          />
          <polygon
            id="Fill-44"
            fill="#FFA535"
            points="26.5506 13.8602 26.5506 29.8102 40.7306 38.0002 50.5506 32.3302 50.5506 16.1702 36.5506 8.0802"
          />
          <polyline id="Stroke-45" stroke="#FEFFCB" stroke-width="3" points="47.4505 23.16 47.4505 19 40.4505 14.92" />
          <line x1="47.4505" y1="28.16" x2="47.4505" y2="25" id="Stroke-46" stroke="#FEFFCB" strokeWidth="3" />
          <polygon
            id="Fill-47"
            fill="#E48023"
            points="53.45 16.1703 53.45 32.3303 67.45 40.4103 81.45 32.3303 81.45 16.1703 67.45 8.0803"
          />
          <polygon
            id="Fill-48"
            fill="#FFA535"
            points="57.45 13.8602 57.45 29.8102 71.631 38.0002 81.45 32.3302 81.45 16.1702 67.45 8.0802"
          />
          <polyline id="Stroke-49" stroke="#FEFFCB" stroke-width="3" points="78.3504 23.16 78.3504 19 71.3504 14.92" />
          <line x1="78.3504" y1="28.16" x2="78.3504" y2="25" id="Stroke-50" stroke="#FEFFCB" strokeWidth="3" />
          <polygon
            id="Fill-51"
            fill="#E48023"
            points="7.0003 42.3202 7.0003 58.4802 21.0003 66.5702 35.0003 58.4802 35.0003 42.3202 21.0003 34.2302"
          />
          <polygon
            id="Fill-52"
            fill="#FFA535"
            points="11.0003 40.0101 11.0003 55.9601 25.1803 64.1501 35.0003 58.4801 35.0003 42.3201 21.0003 34.2301"
          />
          <polyline
            id="Stroke-53"
            stroke="#FEFFCB"
            stroke-width="3"
            points="31.9002 49.3202 31.9002 45.1502 24.9002 41.0702"
          />
          <line x1="31.9002" y1="54.3197" x2="31.9002" y2="51.1497" id="Stroke-54" stroke="#FEFFCB" strokeWidth="3" />
          <polygon
            id="Fill-55"
            fill="#E48023"
            points="38.0003 42.3202 38.0003 58.4802 52.0003 66.5702 66.0003 58.4802 66.0003 42.3202 52.0003 34.2302"
          />
          <polygon
            id="Fill-56"
            fill="#FFA535"
            points="42.0003 40.0101 42.0003 55.9601 56.1803 64.1501 66.0003 58.4801 66.0003 42.3201 52.0003 34.2301"
          />
          <polyline
            id="Stroke-57"
            stroke="#FEFFCB"
            stroke-width="3"
            points="62.9002 49.3202 62.9002 45.1502 55.9002 41.0702"
          />
          <line x1="62.9002" y1="54.3197" x2="62.9002" y2="51.1497" id="Stroke-58" stroke="#FEFFCB" strokeWidth="3" />
          <polygon
            id="Fill-59"
            fill="#E48023"
            points="68.9002 42.3202 68.9002 58.4802 82.9002 66.5702 96.9002 58.4802 96.9002 42.3202 82.9002 34.2302"
          />
          <polygon
            id="Fill-60"
            fill="#FFA535"
            points="72.9002 40.0101 72.9002 55.9601 87.0802 64.1501 96.9002 58.4801 96.9002 42.3201 82.9002 34.2301"
          />
          <polyline
            id="Stroke-61"
            stroke="#FEFFCB"
            stroke-width="3"
            points="93.8006 49.3202 93.8006 45.1502 86.8006 41.0702"
          />
          <line x1="93.8006" y1="54.3197" x2="93.8006" y2="51.1497" id="Stroke-62" stroke="#FEFFCB" strokeWidth="3" />
          <polygon
            id="Fill-63"
            fill="#E48023"
            points="22.5506 68.4701 22.5506 84.6301 36.5506 92.7201 50.5506 84.6301 50.5506 68.4701 36.5506 60.3901"
          />
          <polygon
            id="Fill-64"
            fill="#FFA535"
            points="26.5506 66.1605 26.5506 82.1095 40.7306 90.3005 50.5506 84.6305 50.5506 68.4705 36.5506 60.3895"
          />
          <polyline
            id="Stroke-65"
            stroke="#FEFFCB"
            stroke-width="3"
            points="47.4505 75.4701 47.4505 71.3001 40.4505 67.2201"
          />
          <line x1="47.4505" y1="80.4701" x2="47.4505" y2="77.3001" id="Stroke-66" stroke="#FEFFCB" strokeWidth="3" />
          <polygon
            id="Fill-67"
            fill="#E48023"
            points="53.45 68.4701 53.45 84.6301 67.45 92.7201 81.45 84.6301 81.45 68.4701 67.45 60.3901"
          />
          <polygon
            id="Fill-68"
            fill="#FFA535"
            points="57.45 66.1605 57.45 82.1095 71.631 90.3005 81.45 84.6305 81.45 68.4705 67.45 60.3895"
          />
          <polyline
            id="Stroke-69"
            stroke="#FEFFCB"
            stroke-width="3"
            points="78.3504 75.4701 78.3504 71.3001 71.3504 67.2201"
          />
          <line x1="78.3504" y1="80.4701" x2="78.3504" y2="77.3001" id="Stroke-70" stroke="#FEFFCB" strokeWidth="3" />
        </g>
      </g>
    </Svg>
  );
};

export default Icon;
